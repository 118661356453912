import { classNames } from '@infotrack/infotrackgo.web.core/framework';
import React, { ReactNode } from 'react';
import { HomeHeader } from '../../headers/HomeHeader/HomeHeader';
import { BaseLayout, BaseLayoutProps, Footer, MetaTagsProps } from '@infotrack/infotrackgo.web.core/components';
import { Settings } from '~/framework/setting';

import styles from './home-layout.module.scss';

interface Props extends BaseLayoutProps {
    children: ReactNode;
    // Allows us to show content under the header if need be.
    headerIsTransparent?: boolean;
    // Allows us to invert the colours of the header.
    headerIsInverted?: boolean;
    resetHeaderOnScrollPastHeader?: boolean;
    disableSidedrawer?: boolean;
    disableCenterMenu?: boolean;
}

// Layout component we use for pages that have the navigation links.
export function HomeLayout(props: Props & MetaTagsProps) {
    const bodyClassnames = classNames('', {
        [styles.itgBodyNoMargin]: props.headerIsTransparent
    });

    return (
        <>
            <BaseLayout
                disableGtm={props.disableGtm}
                disableLiveChat={props.disableLiveChat}
                // Meta tag props.
                title={props.title}
                description={props.description}
                showCanonical={props.showCanonical}
                absoluteUrl={props.absoluteUrl}
                googleAnalyticsMeasurementId={Settings.settings.GoogleAnalyticsMeasurementID}
                googleMapsApiKey={Settings.settings.GoogleMapsApiKey}
            >
                {/* Header for the page (sticky) */}
                <HomeHeader
                    headerIsTransparent={props.headerIsTransparent}
                    headerIsInverted={props.headerIsInverted}
                    resetHeaderOnScrollPastHeader={props.resetHeaderOnScrollPastHeader}
                    disableSidedrawer={props.disableSidedrawer}
                    disableCenterMenu={props.disableCenterMenu}
                />
                {/* Main content for the page (takes full height) */}
                <div id="itg-body" className={bodyClassnames}>
                    {props.children}
                </div>
                {/* Footer for the page (very bottom of viewport) */}
                <Footer/>
            </BaseLayout>
        </>
    );
}

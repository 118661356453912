import { Typography } from '@mui/material';
import AnimatedSearch from '../../../public/icons/animated/search_2.json';
import AnimatedPointer from '../../../public/icons/animated/Pointer.json';
import AnimatedInbox from '../../../public/icons/animated/inbox.json';
import { AnimatedIcon } from '~/components/AnimatedIcon/AnimatedIcon';

// Styles
import styles from './home-page-main-content.module.scss';

// A component for the main left section of our pages/index.tsx
export function HomePageMainContent() {
    return (
      <div className={styles.itgHomeContent}>
        <Typography
            variant="h5"
            fontWeight={700}
            className={styles.itgHomeContentTitle}
        >
            The stress free way to search in 3 easy steps
        </Typography>
        <div className={styles.infoSection}>
            <div className={styles.infoSectionCard}>
                <AnimatedIcon
                    src={AnimatedSearch}
                    animationClassContainer={styles.animatedContent}
                />
                <Typography
                    variant="body1"
                    className={styles.infoCardText}
                >
                    Search for the information you need in our intuitive search bar.
                </Typography>
            </div>
            <div className={styles.infoSectionCard}>
                <AnimatedIcon
                    src={AnimatedPointer}
                    animationClassContainer={styles.animatedContent}
                />
                <Typography
                    variant="body1"
                    className={styles.infoCardText}
                >
                    Select the reports you would like to view by adding them to your cart.
                </Typography>
            </div>
            <div className={styles.infoSectionCard}>
                <AnimatedIcon
                    src={AnimatedInbox}
                    animationClassContainer={styles.animatedContent}
                />
                <Typography
                    variant="body1"
                    className={styles.infoCardText}
                >
                    Your reports are promptly delivered to your InfoTrackGO account or email inbox.
                </Typography>
            </div>
        </div>
    </div>
  );
}

import React from 'react';
import { classNames, useWindowSize } from '@infotrack/infotrackgo.web.core/framework';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NoSsr } from '@infotrack/infotrackgo.web.core/components';
import { Breakpoints, ServiceTypes } from '@infotrack/infotrackgo.web.core/enums';
import { SearchMenuIcon } from '~/components/Icons/SearchMenuIcon';
import { handleMenuItems } from '~/components/SearchBars/HeaderSearchBar/SearchMenu/framework/handleMenuItems';
import { observer } from 'mobx-react-lite';
import { useSearchStore, SearchProvider } from '~/stores/search/context/SearchProvider';
import { VerticalSearchFormSelector } from '../VerticalSearchFormSelector/VerticalSearchFormSelector';

// Styles
import styles from './home-search-box.module.scss';

interface HomeSearchBoxProps {
    uniqueId?: string;
    classNames?: string;
}
interface HeaderSearchBoxProps extends HomeSearchBoxProps{
    items: (string | ServiceTypes)[],
    onSelectItem: (selected: ServiceTypes) => void,
    selectedItem: ServiceTypes
}

const HomeSearchBoxTabs = ({ items, onSelectItem, selectedItem, uniqueId, classNames: propsClassNames }: HeaderSearchBoxProps) => {
    return (
        <div className={classNames(styles.tabContainer, propsClassNames ?? '')} id={uniqueId}>
            <div className={styles.tabHeader}>
                {items.map((serviceType: string | ServiceTypes) => {
                    return (
                        <div
                            className={classNames(styles.tabHeaderItem, { [styles.tabHeaderItemSelected]: ServiceTypes[serviceType] === selectedItem })}
                            onClick={() => onSelectItem(ServiceTypes[serviceType])}
                            key={`home-search-box-item-${ServiceTypes[serviceType]}`}
                        >
                            <SearchMenuIcon serviceType={serviceType.toString()} />
                            <Typography variant='body2' className={styles.tabHeaderItemText}>
                                {serviceType}
                            </Typography>
                        </div>
                    );
                })}
            </div>
            <div className={styles.tabBody}>
                <VerticalSearchFormSelector key={'home-search-box-selector'}/>
            </div>
        </div>
    );
}

/**
 * Mobile version. Includes Posbn and Police check menu items
 */
const HomeSearchBoxButtons = ({ items, onSelectItem, selectedItem, uniqueId, classNames: propsClassNames }: HeaderSearchBoxProps) => {
    return (
        <div className={classNames(styles.btnGroupContainer, propsClassNames ?? '')} id={uniqueId}>
            <div className={styles.btnGroupHeader}>
                {items.map((serviceType: string | ServiceTypes) => {
                    return (
                        <Button
                            className={classNames(styles.btnGroupHeaderItem, { [styles.btnGroupHeaderItemSelected]: ServiceTypes[serviceType] === selectedItem })}
                            onClick={() =>  onSelectItem(ServiceTypes[serviceType])}
                            key={`home-search-box-item-${serviceType.toString()}`}
                        >
                            <SearchMenuIcon serviceType={serviceType.toString()} />
                            <Typography variant='body2'>
                                {serviceType.toString()}
                            </Typography>
                        </Button>
                    );
                })}
            </div>
            <div className={styles.btnGroupSearch}>
                <VerticalSearchFormSelector
                    key={'home-search-box-selector'}
                    formContainerClassName={styles.btnGroupSearchForm}
                />
            </div>
        </div>
    );
}

export const HomeSearchBox = observer(function HomeSearchBoxFunc(props: HomeSearchBoxProps) {
    const items = handleMenuItems();
    const { selectedVertical, setSelectedVertical } = useSearchStore();
    const mediumThresholdBreached = ((useWindowSize()?.width || 0) < Breakpoints.md);

    const onChangeVerticalItem = (selectedServiceType: ServiceTypes) => {
        // Only update tab is any different
        if (selectedServiceType !== selectedVertical) {
            // Clear any vertical specific searches when changing tabs
            setSelectedVertical(selectedServiceType);
        }
    }

    return (
        <NoSsr key="home-search-box">
            {! mediumThresholdBreached
                ? <HomeSearchBoxTabs items={items} onSelectItem={onChangeVerticalItem} selectedItem={selectedVertical} {...props} />
                : <HomeSearchBoxButtons items={items} onSelectItem={onChangeVerticalItem} selectedItem={selectedVertical} {...props} />
            }
        </NoSsr>
    );
})

export function WrappedHomeSearchBox(props: HomeSearchBoxProps) {
    return (
        <SearchProvider>
            <HomeSearchBox {...props} />
        </SearchProvider>
    );
}

import React from 'react';
import Link from 'next/link';
import { Typography } from '@mui/material';
import { WrappedHomeSearchBox } from '~/components/SearchBox/HomeSearchBox/HomeSearchBox';

// Styles.
import styles from './homecenterpane.module.scss';

export const ICONS_BAR_ID = 'itg-icons-bar';

interface HomeCenterPaneProps {
    shakeSearchbar?: boolean;
}

function BannerSubtitle() {
    return (
        <Typography
            color="white"
            variant="body1"
            className={styles.bannerSubtitleContainer}
        >
            <span className={styles.bannerSubtitleText}>Looking to complete a police check? &nbsp;</span>
            <Link href={`/forms/police-check`}>
                <span className={styles.bannerSubtitleLink} id="policecheck-link">
                    Police Check
                </span>
            </Link>
            &nbsp; <span className={styles.bannerSubtitleText}> | </span> &nbsp;
            <span className={styles.bannerSubtitleText}>Looking to complete an ownership search? &nbsp;</span>
            <Link href={`/forms/property/ownername-search`}>
                <span className={styles.bannerSubtitleLink} id="posbn-link">
                    Ownership Search
                </span>
            </Link>
        </Typography>
    );
}

/**
 *  Home page center content.
 */
export default function HomeCenterPane({ shakeSearchbar }: HomeCenterPaneProps) {
    return (
        <div className={styles.itgHomePaneCenter}>
            <div className={styles.itgHomePaneCenterSearchbar}>
                <WrappedHomeSearchBox />
                <BannerSubtitle />
            </div>
        </div>
    );
}

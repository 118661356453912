import { useEffect, useState } from 'react';
import { BaseHeader } from '../BaseHeader';
import ITGLogo from '~/public/logo-brand/InfoTrackGO_Full_Colour.svg';
import ITGLogoInverse from '~/public/logo-brand/InfoTrackGO_Full_Reverse.svg';
import { ShoppingCart } from '~/components/ShoppingCart';
import { classNames } from '@infotrack/infotrackgo.web.core/framework';
import dynamic from 'next/dynamic';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { NoSsr, ScrollAwareProps, scrollAware } from '@infotrack/infotrackgo.web.core/components';

// Home Header styles
import styles from '../headers.module.scss';

const DynamicSideDrawer: React.ComponentType<any> = dynamic(() => import('~/components/SideDrawer/SideDrawer'));
// Code split dynamic imports...
const LazyUserAccount: React.ComponentType<any> = dynamic(() => import('~/components/UserAccount'));

interface Props {
    headerIsTransparent?: boolean;
    headerIsInverted?: boolean;
    resetHeaderOnScrollPastHeader?: boolean;
    disableSidedrawer?: boolean;
    disableCenterMenu?: boolean;
}

function HomeHeaderImp(props: Props & ScrollAwareProps) {
    const [resetThreshold, setResetThreshold] = useState<number>();
    const [sidedrawerIsVisible, setSidedrawerIsVisible] = useState(false);

    let logo = ITGLogo;
    let baseHeaderClassnames = classNames('', {
        [styles.itgHeaderTransparent]: props.headerIsTransparent,
        [styles.itgHeaderInverted]: props.headerIsInverted
    });

    if (props.headerIsInverted) logo = ITGLogoInverse;

    useEffect(() => {
        if (props.resetHeaderOnScrollPastHeader) {
            const header = document.getElementById(styles.itgHeader);
            const rect = header?.getBoundingClientRect();
            setResetThreshold(rect?.height);
        }
    }, [process.browser]);

    if (props.resetHeaderOnScrollPastHeader && props.offsetY > (resetThreshold || 0)) {
        baseHeaderClassnames = '';
        logo = ITGLogo;
    }

    return (
        <NoSsr>
            {!props.disableSidedrawer ? <DynamicSideDrawer drawerIsOpen={sidedrawerIsVisible} drawerOnClose={() => setSidedrawerIsVisible(!sidedrawerIsVisible)} /> : null}
            <BaseHeader className={baseHeaderClassnames}>
                <div className={`${styles.itgHeaderSideSection} ${styles.leftmost}`}>
                    {!props.disableSidedrawer ? (
                        <div className={styles.itgHeaderHamburger}>
                            <IconButton
                                color="inherit"
                                className={styles.itgHeaderHambuger}
                                aria-label="open drawer"
                                onClick={() => setSidedrawerIsVisible(!sidedrawerIsVisible)}
                                edge="start"
                                sx={{ ...(sidedrawerIsVisible && { display: 'none' }) }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                    ) : null
                    }
                    <a className={styles.itgLogo} id="itg-logo" href="/">
                        {/* Layout of "fill" will make Image tag expand to parent */}
                        <img
                            src={logo.src}
                            alt="Infotrack Go logo"
                        />
                    </a>
                </div>
                {/*
                    The home menu is our centered header content,
                    it is implemented natively and thus is safe to be used
                    even in the SEO pages as it will not bloat with 3rd party code.
                */}
                <div className={`${styles.itgHeaderSideSection} ${styles.rightmost}`}>
                    <ShoppingCart />
                    {/* Note: disabled for now but should put back once we have a native solution... */}
                    <LazyUserAccount />
                </div>
            </BaseHeader>
        </NoSsr>
    );
}

export const HomeHeader = scrollAware<Props>(HomeHeaderImp);
